import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../../components/Button";
import SearchField from "../../../../components/Fields/SearchField";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import confirmAction from "../../../../libs/confirm_action";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import ContainerList from "./components/ContainerList";

const CONTAINERS = loader("./SupplyContainerChoose.query.graphql");
const CHOOSE_CONTAINER = loader("./SupplyContainerChoose.mutation.graphql");
const SUPPLY_ITEM_MUTATION = loader("./SupplyItemContainer.mutation.graphql");

function SupplyContainerChoose() {
  const intl = useIntl();
  const { phaseId, sessionId, type, itemId, pickingId, stepId } = useParams();
  const history = useHistory();
  const { stationId } = useSelector(({ config }) => config);
  const container = useSelector(({ container }) => container);

  const [mutate] = useMutation(CHOOSE_CONTAINER);
  const [mutateLink] = useMutation(SUPPLY_ITEM_MUTATION, {
    variables: { sessionId, pickingId },
  });

  const variables = { phaseId, sessionId, stationId };

  const { data, loading, error } = useQuery(CONTAINERS, { variables });

  const [search, setSearch] = useState("");

  function filterer(container) {
    if (search && search.length === 0) {
      return true;
    }
    const rxSearch = new RegExp(
      search.replace(/[^a-z0-9]/gi, "[^a-z0-9]"),
      "i"
    );
    if (rxSearch.test(container.name)) {
      return true;
    }
    if (rxSearch.test(container.code)) {
      return true;
    }
    if (rxSearch.test(container.item?.name)) {
      return true;
    }
    if (rxSearch.test(container.item?.code)) {
      return true;
    }
    return false;
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { containers, session } = data;
  const pickingItem = session?.picking?.find((p) => p._id === pickingId);
  const selectedContainers = pickingItem?.containers.map((c) => c._id) ?? [];

  // Containers without item
  let nullFiltered = containers?.filter((c) => !c._item || !itemId) ?? [];
  // Containers with item selected
  let itemFiltered = [];
  if (itemId != null) {
    itemFiltered = containers?.filter((c) => c._item == itemId) ?? [];
  }
  // Containers with item not selected
  let notItemFiltered = [];
  if (itemId != null) {
    notItemFiltered =
      containers?.filter((c) => c._item != itemId && c._item != null) ?? [];
  }

  var groupedContainers = [
    ...itemFiltered,
    ...notItemFiltered,
    ...nullFiltered,
  ];

  const filtered = groupedContainers.filter(filterer);

  async function onContainerClick(container) {
    checkItem(container, () => chooseContainer(container._id, null));
  }

  async function checkItem(container, callback) {
    if (itemId != null) {
      if (container && itemId !== container._item) {
        return confirmAction({
          confirm: async () => {
            /*await mutateItem({
              variables: {
                containerId: container._id,
                itemId,
              },
            })*/
            callback();
          },
          options: {
            text: intl.formatMessage({
              id: "app.pages.application.supply_item_container.warning.item_different",
              defaultMessage: "Warning, container item is different",
            }),
          },
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  async function chooseContainer(containerId, code) {
    if (container.action == "LINK") {
      linkContainer(containerId);
    } else {
      try {
        await mutate({
          variables: {
            containerId,
            code,
            phaseId,
            sessionId,
            type,
          },
        });
        if (container.action == "WITHDRAW") {
          return history.replace(
            "/application/withdraw-container/qty/" +
              containerId +
              "/" +
              phaseId +
              "/" +
              sessionId +
              "/" +
              pickingId +
              "/" +
              stepId
          );
        } else {
          return history.goBack();
        }
      } catch (e) {
        onError(e);
      }
    }
  }

  async function linkContainer(_containerId) {
    //add _containerId to selectedContainers
    const _containers = [...selectedContainers, _containerId];
    try {
      const variables = {
        //containerId: _container,
        itemId: pickingItem._item,
        picking: { _item: itemId, _containers },
      };
      await mutateLink({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      return history.go(-2);
    } catch (error) {
      onError(error);
    }
  }

  const theme = themes.neutralGrey;

  return (
    <>
      <Helmet>
        <title>Choose container - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasSessionInfo={false} hasUserInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt="Back"
                  />
                </Button>
              </div>
              <div className="column is-7"></div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column" style={{ paddingLeft: "3.2rem" }}>
                <Title.H1 className="title is-2">
                  <FormattedMessage
                    id="app.pages.application.containers.add.title"
                    defaultMessage="Add containers"
                  />
                </Title.H1>
                <Subtitle.S3 className="subtitle is-4" theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.containers.subtitle"
                    defaultMessage="Configure container"
                  />
                </Subtitle.S3>
              </div>
              <div
                className="column"
                style={{ position: "relative", paddingRight: "2.8rem" }}
              >
                <SearchField
                  autofocus={false}
                  placeholder={intl.formatMessage({
                    id: "app.pages.application.containers.search.placeholder",
                    defaultMessage: "Search container...",
                  })}
                  defaultValue={search}
                  onChange={(value) => setSearch(value)}
                />
              </div>{" "}
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <ContainerList
                  containers={filtered}
                  onClick={(container) => {
                    onContainerClick(container);
                  }}
                  session={session}
                />
              </div>
              {containers.length === 0 && (
                <div className="column">
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.application.containers.empty"
                      defaultMessage="Containers not configured, please contact a manager"
                    />
                  </Title.H2>
                </div>
              )}
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyContainerChoose.propTypes = {};
export default SupplyContainerChoose;

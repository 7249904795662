import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";

import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import themes from "../../../../libs/themes";
import { setContainerItem, setContainerPickingId } from "../../../../actions";
import Button from "../../../../components/Button";
import SearchField from "../../../../components/Fields/SearchField";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import confirmAction from "../../../../libs/confirm_action";
import onError from "../../../../libs/error-logger";
import toast from "../../../../libs/toast";
import ContainerList from "./components/ContainerList";

const SUPPLY_ITEM = loader("./SupplyItemContainer.query.graphql");
const SUPPLY_ITEM_MUTATION = loader("./SupplyItemContainer.mutation.graphql");

function SupplyItemContainer() {
  const intl = useIntl();
  const [containerIds, setContainerIds] = useState();
  const { phaseId, sessionId, pickingId, stepId } = useParams();
  const history = useHistory();
  const { stationId, hmiConfiguration } = useSelector(({ config }) => config);
  const dispatch = useDispatch();
  const container = useSelector(({ container }) => container);

  const variables = { phaseId, sessionId, pickingId, stationId };

  if (hmiConfiguration.FILTER_CONTAINERS_STATION) {
    // Add warehouse filter when station is null
    variables.where = { _or: [{ _station: stationId }, { _station: null }] };
  }

  const { data, loading, error } = useQuery(SUPPLY_ITEM, { variables });

  const [mutate] = useMutation(SUPPLY_ITEM_MUTATION, {
    variables: { sessionId, pickingId },
  });

  const theme = themes.materialViolet;
  const [search, setSearch] = useState("");

  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { session, containers } = data;
  const { pickingItem } = session;

  var filteredContainers = containers.filter(
    (c) => c._item === pickingItem._item
  );

  //Sort containers by maximum current value (descending)
  filteredContainers.sort((a, b) => {
    if (a.current < b.current) {
      return 1;
    }
    if (a.current > b.current) {
      return -1;
    }
    return 0;
  });

  function filterer(container) {
    if (pickingItem.main) {
      if (container.hasSerialnumbers) {
        return true;
      }
      return false;
    }
    if (search && search.length === 0) {
      return true;
    }
    const rxSearch = new RegExp(
      search.replace(/[^a-z0-9]/gi, "[^a-z0-9]"),
      "i"
    );
    if (rxSearch.test(container.name)) {
      return true;
    }
    if (rxSearch.test(container.code)) {
      return true;
    }
    if (rxSearch.test(container.item?.name)) {
      return true;
    }
    if (rxSearch.test(container.item?.code)) {
      return true;
    }
    return false;
  }

  var othersContainers = containers.filter(
    (c) => c._item !== pickingItem._item
  );

  var allContainers = !hmiConfiguration.FILTER_CONTAINERS_STATION
    ? filteredContainers
    : filteredContainers.concat(othersContainers).filter(filterer);

  const selectedContainerIds =
    containerIds ?? pickingItem.containers.map((c) => c._id);

  async function checkContent(_containerId) {
    // TODO: If content is different, shows warning, you will empty container and change content
    for (let i = 0; i < selectedContainerIds.length; i++) {
      const container = containers.find(
        (container) => container._id === selectedContainerIds[i]
      );
      if (container && pickingItem._item !== container._item) {
        return confirmAction({
          confirm: () => onSubmit([...selectedContainerIds, _containerId]),
          options: {
            text: intl.formatMessage({
              id: "app.pages.application.supply_item_container.warning.item_different",
              defaultMessage: "Warning, container item is different",
            }),
          },
        });
      }
    }
    await onSubmit([...selectedContainerIds, _containerId]);
  }

  async function onSubmit(_containerIds) {
    try {
      const variables = {
        //containerId: _container,
        itemId: pickingItem._item,
        picking: { _item: pickingItem._item, _containers: _containerIds },
      };
      await mutate({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      return history.goBack();
    } catch (error) {
      onError(error);
    }
  }

  function toggleContainerId(_container) {
    if (container.action == "WITHDRAW") {
      dispatch(setContainerPickingId(pickingId));
      return history.push(
        "/application/withdraw-container/qty/" +
          _container +
          "/" +
          phaseId +
          "/" +
          sessionId +
          "/" +
          pickingId +
          "/" +
          stepId
      );
    }

    if (selectedContainerIds.find((c) => c === _container) === undefined) {
      setContainerIds([...selectedContainerIds, _container]);
      checkContent(_container);
    }
  }

  return (
    <>
      <Helmet>
        <title>Supply Item Container - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <FormattedMessage
                    id="app.shared.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
              <div className="column">
                {
                  <Button
                    theme={themes.primary.buttons}
                    isFullWidth
                    onClick={() => {
                      dispatch(setContainerPickingId(pickingId));
                      history.push(
                        `/application/container-add/${
                          pickingItem.main ? "serialnumber" : "default"
                        }/${phaseId}/${sessionId}/${pickingItem._item}`
                      );
                    }}
                    data-cy="application-supply_item_container-button-add"
                  >
                    <FormattedMessage
                      id="app.shared.container-add"
                      defaultMessage="Nuovo contenitore"
                    />
                  </Button>
                }
              </div>
              <div className="column">
                {container.action == "WITHDRAW" && (
                  <Button
                    isFullWidth
                    onClick={() => {
                      dispatch(setContainerItem(pickingItem._item));
                      history.push(
                        "/application/withdraw-none/qty/" +
                          phaseId +
                          "/" +
                          sessionId +
                          "/" +
                          pickingId +
                          "/" +
                          stepId
                      );
                    }}
                    data-cy="application-supply_item_container-button-skip"
                  >
                    <FormattedMessage
                      id="app.shared.skip"
                      defaultMessage="Skip"
                    />
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <>
            <div className="columns is-vcentered">
              <div className="column is-6" style={{ paddingLeft: "3.2rem" }}>
                <Title.H2>
                  <FormattedMessage
                    id="app.pages.application.supply_item_container.title"
                    defaultMessage="Choose container"
                  />
                </Title.H2>
                <Subtitle.S4 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.supply_item_container.subtitle"
                    defaultMessage="Configure container per {item}"
                    values={{
                      item:
                        pickingItem.item.code + " :: " + pickingItem.item.name,
                    }}
                  />
                </Subtitle.S4>
              </div>
              <div className="column" style={{ paddingRight: "2.8rem" }}>
                <SearchField
                  autofocus={false}
                  placeholder={intl.formatMessage({
                    id: "app.pages.application.containers.search.placeholder",
                    defaultMessage: "Search container...",
                  })}
                  defaultValue={search}
                  onChange={(value) => setSearch(value)}
                />
              </div>
            </div>
            <div
              className="columns has-text-centered"
              style={{ marginTop: "1.6rem" }}
            >
              {/*containers.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.shared.not_found"
                      defaultMessage="Container not found"
                    />
                  </Title.H2>
               )*/}
              <div className="column has-20-margin-top">
                <ContainerList
                  containers={allContainers}
                  containerIds={selectedContainerIds}
                  onClick={toggleContainerId}
                  session={session}
                  itemId={pickingItem._item}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItemContainer.propTypes = {};
export default SupplyItemContainer;
